import { NextRouter } from 'next/router';
import { createContext, PropsWithChildren, useContext } from 'react';

/**
 * This is SPA router matching URLs
 */
export enum NamedRoute {
    // noinspection JSUnusedGlobalSymbols
    HOME = '/app',
    LOGIN = '/app/login',
    LOGOUT = '/app/logout',
    PROFILE = '/app/profile',
    LOAN_AGREEMENT = '/app/loan-agreement',
    SIGN_AGREEMENT = '/app/sign-agreement',
    SIGN_ADDENDUM = '/app/sign-addendum',
    BANK_VERIFICATION = '/app/bank-verification',
    APPLY_LOAN = '/app/apply-loan',
    LOAN_HISTORY = '/app/loan-history',
    ADD_DEBIT_CARD = '/app/add-debit-card',
    SCHEDULE_PAY_OFF = '/app/schedule-pay-off',
    MAKE_PAYMENT = '/app/make-payment',
    PASSWORD_CHANGE = '/app/password',
    PASSWORD_REMIND = '/app/password/remind/:email?',
    PASSWORD_RESET = '/app/password/reset/:email?/:token?',
    CREATE_APPLICATION = '/app/application',
    VERIFY_ENTITY = '/app/verify',
    SEARCH_PARTNER = '/app/search-partner',
    CURRENT_BALANCE = '/app/dmcc',
    CUSTOMERS_LANDING= '/app/customers-landing'
}

export function currentPage(router: NextRouter) {
    return router.asPath.split('?')[0] || '';
}

export interface PageRoute {
    path: string;
    pagePath: string;
    params: { [key: string]: any; },
}

export interface RouteProps extends PropsWithChildren {
    path?: string;
}

export type RoutePageProps = PropsWithChildren & RouteProps;

const RX_ROUTE_CLEAN = /(\/:.*?\??)(\/|$|\?[^:]+)/ig;

// noinspection JSUnusedGlobalSymbols
export function makeUrl(
    route: NamedRoute,
    params?: { [key: string]: any; },
): string {
    let url = route as string;

    if (params) {
        for (const name of Object.keys(params)) {
            const pattern = `:${ name }\\?`;

            if (params[name] instanceof Array) {
                const rx = new RegExp(pattern);

                for (let i = 0; i < params[name].length; i++) {
                    url = url.replace(rx, params[name][i]);
                }
            } else {
                url = url.replace(new RegExp(pattern, 'g'), params[name]);
            }
        }
    }

    return url.replace(RX_ROUTE_CLEAN, '$2');
}

export const RouteContext = createContext<PageRoute | null>(null);

export default function useNamedRoute(): PageRoute | null {
    return useContext(RouteContext);
}
