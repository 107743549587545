import { toOrdinalNumber } from '../common/helpers/format';

export enum loyaltyStatuses {
    BRONZE = 'Bronze',
    SILVER = 'Silver',
    GOLD = 'Gold',
    PLATINUM = 'Platinum',
    VIP = 'VIP',
}

export enum LoanDocuments {
    AGREEMENT = 'Loan Agreement',
    CONSENT = 'CONSENT TO ELECTRONIC COMMUNICATIONS',
    DISBURSEMENT = 'DISBURSEMENT AND PAYMENT CHOICE AUTHORIZATION for Credit Cube',
    POLICY = 'Privacy Policy',
    GOVERNING_LAW = 'GOVERNING LAW',
}

export enum MFAStatus {
    CredentialsRequired = 'CredentialsRequired',
    CodeRequired = 'CodeRequired',
    NothingRequired = 'NothingRequired',
}

export type LoyaltyStatusType = 'Bronze' | 'Silver' | 'Gold' | 'Platinum' |
    'VIP';

export type Token = string | string[] | undefined;

export type LoanSupportAction = 'Select payment plan' | 'Pay with a debit card';

export interface LoyaltyItem {
    status: LoyaltyStatusType;
    discount: number;
    points: number;
}

export const LoyaltyItems: LoyaltyItem[] = [
    { status: loyaltyStatuses.BRONZE, discount: 0, points: 0 },
    { status: loyaltyStatuses.SILVER, discount: 16, points: 700 },
    { status: loyaltyStatuses.GOLD, discount: 33, points: 1600 },
    { status: loyaltyStatuses.PLATINUM, discount: 50, points: 3000 },
    { status: loyaltyStatuses.VIP, discount: 66, points: 5000 },
];

export const CARD_MONTHS = [
    '01', '02', '03', '04',
    '05', '06', '07', '08',
    '09', '10', '11', '12',
];

export const CARD_YEARS = [
    '23', '24', '25', '26',
    '27', '28', '29', '30',
    '31', '32', '33', '34',
    '35', '36', '37', '38',
    '39', '40', '41', '42',
];

export const MINIMUM_PAYMENT_AMOUNT = 50;

export const LOCALE = 'en-US';
export const CURRENCY = 'USD';

export const STATES: { [key: string]: string } = {
    'AL': 'Alabama', 'AK': 'Alaska', 'AZ': 'Arizona', 'AR': 'Arkansas',
    'CA': 'California', 'CO': 'Colorado', 'CT': 'Connecticut', 'DC': 'Washington DC',
    'DE': 'Delaware', 'FL': 'Florida', 'GA': 'Georgia', 'HI': 'Hawaii',
    'ID': 'Idaho', 'IL': 'Illinois', 'IN': 'Indiana', 'IA': 'Iowa',
    'KS': 'Kansas', 'KY': 'Kentucky', 'LA': 'Louisiana', 'ME': 'Maine',
    'MD': 'Maryland', 'MA': 'Massachusetts', 'MI': 'Michigan', 'MN': 'Minnesota',
    'MS': 'Mississippi', 'MO': 'Missouri', 'MT': 'Montana', 'NE': 'Nebraska',
    'NV': 'Nevada', 'NH': 'New Hampshire', 'NJ': 'New Jersey', 'NM': 'New Mexico',
    'NY': 'New York', 'NC': 'North Carolina', 'ND': 'North Dakota', 'OH': 'Ohio',
    'OK': 'Oklahoma', 'OR': 'Oregon', 'PA': 'Pennsylvania', 'RI': 'Rhode Island',
    'SC': 'South Carolina', 'SD': 'South Dakota', 'TN': 'Tennessee', 'TX': 'Texas',
    'UT': 'Utah', 'VT': 'Vermont', 'VA': 'Virginia', 'WA': 'Washington',
    'WV': 'West Virginia', 'WI': 'Wisconsin', 'WY': 'Wyoming',
};

export const APPLICATION_PASSWORD_LENGTH = 8;

export const LOAN_AMOUNT = [
    '200', '300', '400', '500',
    '600', '700', '800', '900',
    '1000', '1100', '1200', '1300',
    '1400', '1500', '1600', '1700',
    '1800', '1900', '2000', '2100',
    '2200', '2300', '2400', '2500',
    '2600', '2700', '2800', '2900',
    '3000', '3100', '3200', '3300',
    '3400', '3500', '3600', '3700',
    '3800', '3900', '4000', '4100',
    '4200', '4300', '4400', '4500',
    '4600', '4700', '4800', '4900', '5000',
];

export const PAY_FREQUENCIES = [
    'Weekly', 'Bi-Weekly', 'Semi-Monthly', 'Monthly',
];

export const PAY_FREQUENCIES_MAP: { [key: string]: string } = {
    'Daily': 'Daily',
    'Weekly': 'Weekly',
    'BiWeekly': 'Bi-Weekly',
    'SemiMonthly': 'Semi-Monthly',
    'Monthly': 'Monthly',
    'Other': 'Other',
};

export const BUSINESS_DAYS = [
    'Monday', 'Tuesday', 'Wednesday',
    'Thursday', 'Friday',
];

export const WEEK_DAYS_MAP: { [key: string]: string } = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
};

export const MONTH_LENGTH = 31;

export const MONTH_DAYS: string[] = new Array(MONTH_LENGTH).fill(
    0,
).map((_, i) => toOrdinalNumber(i + 1));
MONTH_DAYS[MONTH_DAYS.length - 1] = 'EOM';

export const ACCOUNT_TYPES = [
    'Checking', 'Savings',
];

export const ACCOUNT_TYPES_MAP = {
    'CHECKING': 'Checking', 'SAVINGS': 'Savings',
};

export const HOLIDAY_SKIPPING = [
    'Before', 'After',
];

export const US_DATE_FORMAT = 'MM/dd/yyyy';

export const LAST_DAY_OF_MONTH = '31';

export const POSSIBLE_LOAN_AMOUNT_DIFFERENCE = 50;

export const INCOME_TYPE: { [key: string]: string } = {
    'Employment': 'Employment',
    'SocialSecurity': 'Social Security',
    'Disability': 'Disability',
    'Retirement': 'Retirement',
    'SelfEmployed': 'Self-Employed',
    'Other': 'Other',
};

export const PAYMENT_REVERSE_REASONS_MAP: { [key: string]: string } = {
    'Manual': 'Manual',
    'AchReturn': 'Ach Return',
    'DebitCardDeclined': 'Debit Card Declined',
    'ExpressLoanReversed': 'Express Loan Reversed',
};

export const POLLING_INTERVAL_MS = 2000;

export enum createAppSteps {
    CREATE_ACCOUNT = 1,
    PERSONAL_INFORMATION = 2,
    WORK_INFORMATION = 3,
    BANK_INFORMATION = 4,
}

export enum VerificationMethods {
    Phone = 'Phone',
    Email = 'Email',
}

export const LOAN_APPROVAL_AGE = 18;

export const RECAPTCHA_CONTACT_US = 'contact_us';
export const RECAPTCHA_MA_LOGIN = 'login_ma';
export const RECAPTCHA_RESET_PASSWORD = 'customer_reset_password';
// noinspection JSUnusedGlobalSymbols
export const RECAPTCHA_CREATE_APPLICATION = 'create_application';

export const RX_SPACE = /^\s+|\s+$/g;

export const PROFILE_INFO_UPDATE_SUBJECT = 'Profile information update';

export const ERROR_FIELD_MAPPING = {
    'InvalidData: Username is already used': {
        stepId: createAppSteps.CREATE_ACCOUNT,
        fieldId: 'email',
    },
    'InvalidData: Customer Home Zip is not valid': {
        stepId: createAppSteps.PERSONAL_INFORMATION,
        fieldId: 'homeZipCode',
    },
    'InvalidData: Customer Home Phone is not valid': {
        stepId: createAppSteps.PERSONAL_INFORMATION,
        fieldId: 'homePhone',
    },
    'InvalidData: Customer Cell Phone is not valid': {
        stepId: createAppSteps.PERSONAL_INFORMATION,
        fieldId: 'cellPhone',
    },
    'InvalidData: All dates must be between year 1801 and 2200 (BirthDate)': {
        stepId: createAppSteps.PERSONAL_INFORMATION,
        fieldId: 'dateOfBirth',
    },
    'InvalidData: Customer with the given Driver\'s License is already registered':
        {
            stepId: createAppSteps.PERSONAL_INFORMATION,
            fieldId: 'driversLicense',
        },
    'InvalidData: Customer Primary Income Work Zip is not valid': {
        stepId: createAppSteps.WORK_INFORMATION,
        fieldId: 'workZipCode',
    },
    'InvalidData: Customer Primary Income Work Phone is not valid': {
        stepId: createAppSteps.WORK_INFORMATION,
        fieldId: 'workPhone',
    },
    'InvalidData: Customer Primary Income Monthly Income is not valid': {
        stepId: createAppSteps.WORK_INFORMATION,
        fieldId: 'monthlyIncome',
    },
    'InvalidData: ABA Number is not valid': {
        stepId: createAppSteps.BANK_INFORMATION,
        fieldId: 'abaNumber',
    },
    'BankInfo/ABA Number is not valid': {
        stepId: createAppSteps.BANK_INFORMATION,
        fieldId: 'abaNumber',
    },
    'Unacceptable bank!': {
        stepId: createAppSteps.BANK_INFORMATION,
        fieldId: 'abaNumber',
    },
    'InvalidData: Customer SSN is not valid': {
        stepId: createAppSteps.PERSONAL_INFORMATION,
        fieldId: 'ssn',
    },
    'InvalidData: Customer with the given SSN is already registered': {
        stepId: createAppSteps.PERSONAL_INFORMATION,
        fieldId: 'ssn',
    },
    'InvalidData: Customer E-mail is not valid': {
        stepId: createAppSteps.CREATE_ACCOUNT,
        fieldId: 'email',
    },
    'InvalidData: Account Number is not valid': {
        stepId: createAppSteps.BANK_INFORMATION,
        fieldId: 'accountNumber',
    },
};

